/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css"; 

@font-face{
    font-family: 'roboto'; 
    src: url('./assets/font/roboto/Roboto-Regular.ttf');
}
@font-face{
    font-family: 'robotoBold'; 
    src: url('./assets/font/roboto/Roboto-Bold.ttf');
}

ion-text{
    font-family: 'roboto', sans-serif !important;
}
ion-input{
    --font-family: 'roboto', sans-serif !important;
    --background: #f6f6f6 !important;
}
ion-item{
    --font-family: 'roboto', sans-serif !important;
    //--background: #f6f6f6 !important;
}
ion-label{
    --font-family: 'roboto', sans-serif !important;
    padding-bottom: 10px;
}
ion-title{
    --font-family: 'roboto', sans-serif !important;
}
ion-textarea{
    --background: #f6f6f6 !important;
}
ion-button{
    font-family: 'robotoBold', sans-serif !important;
}
ion-toast{
    font-family: 'roboto', sans-serif !important;
    --background: #464646;
    --button-color:white;
    --color: white;
    --box-shadow: 0px 3px 6px #00000070;
}
ion-content{
    overflow: auto; 
}
ion-select{
    background: #f6f6f6 !important;
    width: 100%;
}

ion-toast.newOrderToast{
    --background: #ffffff;
    --box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
    --color: #000000; 
    text-align: center;
    font-size: 15px;
    font-weight: bold;
}   
ion-toast.newOrderToast::part(button){ 
    background-color: #a50016;
    color: white;
    font-size: 15px;
    font-weight: bolder;
    border-radius: 35px;
}

ion-alert.alerts {
    .alert-wrapper{
        --background: white;
        border-radius:13px;
        
        .alert-message.sc-ion-alert-md{
            color:#307AFD;
            font-weight: 100 !important; 
        }
        .alert-title.sc-ion-alert-md{
            color:#307AFD !important;
            font-weight: 500 !important; 
        }  
    .alert-message{
        color: #307AFD;
        font-weight: bold;
        font-size: 18px;
        opacity: 1;
    }
  }
}

 

ion-modal.orderView{
    ion-content{
        --padding-start: 40px;
        --padding-end: 40px;
        --padding-top: 20px;
    }
    .orderStatus{
        height: 110px;
        width: 100%;
        background: black;
        border-radius: 18px;
        padding: 20px;
        ion-text{
            color: white;
            font-weight: bold;
            font-size: 25px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .orderStatusReady{
        height: 110px;
        width: 100%;
        background: #20E800;
        border-radius: 18px;
        padding: 20px;
        ion-text{
            color: white;
            font-weight: bold;
            font-size: 25px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .checklistReceived{
        margin-top: 20px;
        ion-col{
            display: flex;
        }
        ion-text{
            align-self: center;
            padding-left: 10px;
        }
        .receivedIcon{
            color:#000;
            font-size: 35px;
        } 
        .preparingIcon{
            color:#ddd;
            font-size: 35px;
        } 
        .readyIcon{
            color:#ddd;
            font-size: 35px;
        } 
        .line1{
            background: #ddd;
            height: 40px;
            width: 5px;
            margin-left: 15px;
            transform: translateY(-5px);
        } 
        .line2{
            background: #ddd;
            height: 40px;
            width: 5px;
            margin-left: 15px;
            transform: translateY(-5px);
        } 
    } 

    .checklistPreparing{
        margin-top: 20px;
        ion-col{
            display: flex;
        }
        ion-text{
            align-self: center;
            padding-left: 10px;
        }
        .receivedIcon{
            color:#000;
            font-size: 35px;
        } 
        .preparingIcon{
            color:#000;
            font-size: 35px;
        } 
        .readyIcon{
            color:#ddd;
            font-size: 35px;
        } 
        .line1{
            background: #000;
            height: 40px;
            width: 5px;
            margin-left: 15px;
            transform: translateY(-5px);
        } 
        .line2{
            background: #ddd;
            height: 40px;
            width: 5px;
            margin-left: 15px;
            transform: translateY(-5px);
        } 
    } 

    .checklistReady{
        margin-top: 20px;
        ion-col{
            display: flex;
        }
        ion-text{
            align-self: center;
            padding-left: 10px;
        }
        .receivedIcon{
            color:#20E800;
            font-size: 35px;
        } 
        .preparingIcon{
            color:#20E800;
            font-size: 35px;
        } 
        .readyIcon{
            color:#20E800;
            font-size: 35px;
        } 
        .line1{
            background: #20E800;
            height: 40px;
            width: 5px;
            margin-left: 15px;
            transform: translateY(-5px);
        } 
        .line2{
            background: #20E800;
            height: 40px;
            width: 5px;
            margin-left: 15px;
            transform: translateY(-5px);
        } 
    } 

    .checklistFulfilled{
        margin-top: 20px;
        ion-col{
            display: flex;
        }
        ion-text{
            align-self: center;
            padding-left: 10px;
        }
        .receivedIcon{
            color:#000;
            font-size: 35px;
        } 
        .preparingIcon{
            color:#000;
            font-size: 35px;
        } 
        .readyIcon{
            color:#000;
            font-size: 35px;
        } 
        .line1{
            background: #000;
            height: 40px;
            width: 5px;
            margin-left: 15px;
            transform: translateY(-5px);
        } 
        .line2{
            background: #000;
            height: 40px;
            width: 5px;
            margin-left: 15px;
            transform: translateY(-5px);
        } 
    } 


    ion-toolbar{
        --background: transparent !important;
        --border-color: transparent !important;
    }
    ion-footer{
        ion-toolbar{
            ion-thumbnail{
                width: 50%;
                position: absolute;
                left:50%;
                top:50%;
                transform: translate(-50%,-50%);
            }
        }
    }
}

ion-modal.orderModal{
    --border-radius: 28px;
    ion-toolbar{
        --background: transparent !important;
        --border-color: transparent !important;
    }
    ion-footer{
        ion-toolbar{
            ion-thumbnail{
                width: 50%;
                position: absolute;
                left:50%;
                top:50%;
                transform: translate(-50%,-50%);
            }
        }
    }
    .dividier{
        width: 100%;
        height: 3px;
        border-radius: 15px;
        background-color: #ddd;
        margin-top: 5px;
        margin-bottom: 5px;
    }
    .notReady{
        --background: #000;
        --background-activated: #eee; 
    }
    .ready{
        --background: #20E800;
        --background-activated: #85ff72; 
    }
}


   :root {
     /** processing **/
     --ion-color-process: #ff95a2;
     --ion-color-process-rgb: 235, 68, 90;
     --ion-color-process-contrast: #ffffff;
     --ion-color-process-contrast-rgb: 255, 255, 255;
     --ion-color-process-shade: #ca6b77;
     --ion-color-process-tint: #eb8f9b;

     /** preparing **/
     --ion-color-prepping: #f7dc71;
     --ion-color-prepping-rgb: 255, 196, 9;
     --ion-color-prepping-contrast: #000000;
     --ion-color-prepping-contrast-rgb: 0, 0, 0;
     --ion-color-prepping-shade: #dfc15e;
     --ion-color-prepping-tint: #fdd85c;

     /** ready **/
    --ion-color-ready: #5ad38a;
    --ion-color-ready-rgb: 45, 211, 111;
    --ion-color-ready-contrast: #ffffff;
    --ion-color-ready-contrast-rgb: 255, 255, 255;
    --ion-color-ready-shade: #56b67c;
    --ion-color-ready-tint: #82d6a3;
  }
  
  .ion-color-process {
    --ion-color-base: var(--ion-color-process);
    --ion-color-base-rgb: var(--ion-color-process-rgb);
    --ion-color-contrast: var(--ion-color-process-contrast);
    --ion-color-contrast-rgb: var(--ion-color-process-contrast-rgb);
    --ion-color-shade: var(--ion-color-process-shade);
    --ion-color-tint: var(--ion-color-process-tint);
  }
  .ion-color-prepping {
    --ion-color-base: var(--ion-color-prepping);
    --ion-color-base-rgb: var(--ion-color-prepping-rgb);
    --ion-color-contrast: var(--ion-color-prepping-contrast);
    --ion-color-contrast-rgb: var(--ion-color-prepping-contrast-rgb);
    --ion-color-shade: var(--ion-color-prepping-shade);
    --ion-color-tint: var(--ion-color-prepping-tint);
  }
  .ion-color-ready {
    --ion-color-base: var(--ion-color-ready);
    --ion-color-base-rgb: var(--ion-color-ready-rgb);
    --ion-color-contrast: var(--ion-color-ready-contrast);
    --ion-color-contrast-rgb: var(--ion-color-ready-contrast-rgb);
    --ion-color-shade: var(--ion-color-ready-shade);
    --ion-color-tint: var(--ion-color-ready-tint);
  }

 
 
